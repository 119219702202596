import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import React, { useState, Suspense, lazy } from "react";

import {
  Container,
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import Impressum from "./routes/Impressum";

const Home = lazy(() => import("./routes/Home"));
const PTS1Predictor = lazy(() => import("./routes/PTS1Predictor"));
const About = lazy(() => import('./routes/About'));

export default function App() {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <Router>
      <Container>
        <Navbar color="light" sticky={'top'} light expand="md">
          <NavbarBrand tag={Link} to="/">
            Olis-Lab
          </NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="mr-auto" navbar>
              <NavItem>
                <NavLink tag={Link} to="/">
                  Home
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} to="/pts1-prediction-tool">
                  pts1-prediction-tool
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} to="/about">
                  About me
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} to="/impressum">
                  Impressum
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
        {/* A <Switch> looks through its children <Route>s and
                  renders the first one that matches the current URL. */}
        <ErrorBoundary>
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <Route path="/pts1-prediction-tool">
                <PTS1Predictor />
              </Route>
              <Route path="/about">
                <About />
              </Route>
              <Route path="/impressum">
                <Impressum />
              </Route>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          </Suspense>
        </ErrorBoundary>
      </Container>
    </Router>
  );
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    console(error, errorInfo);
  }
  render() {
    if (this.state.hasError) {
      return <Container>Something went wrong.</Container>;
    }
    return this.props.children;
  }
}
