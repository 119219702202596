import {
    Container,
    Row,
    Col,
  } from "reactstrap";

export default function Impressum() {
    return (
      <Container>
        <Row>
          <Col xs="12">
            <h3>Impressum:</h3>
            <p>This site has no commercial intentions.</p>
            <h3>Contact:</h3>    
            <p>Name: Oliver Koch</p>
            <p>E-Mail: ollis.lab@gmail.com</p>
          </Col>
        </Row>
    </Container>
    );
}